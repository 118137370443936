<div>
  <h4>Изменить статус оповещений на обзвон</h4>
  <ng-container>
    <div class="row">
      <div class="col-3">
        <app-strict-input-number
                [control]="viewModel.userId"
                placeholder="Введите Id"
                title="Id пользователя">
        </app-strict-input-number>
      </div>
      <div class="col-3">
        <label>Пользователь отписывается?</label>
        <app-strict-input-boolean [control]="viewModel.isUnsubscribed"></app-strict-input-boolean>
      </div>
      <div class="col-6">
        <label>Введите дату отмены подписки</label>
        <strict-date-time-picker [control]="viewModel.eventDate" [placeholder]="'Дата события'"></strict-date-time-picker>
      </div>
    </div>
  </ng-container>
  <div>
    <button class="btn btn-primary" (click)="save()">Сохранить</button>
  </div>
</div>
