import {Component, OnDestroy, OnInit} from '@angular/core';
import { CouponsService } from 'src/app/services/autogen/Dictionaries';
import {filter, finalize, map, switchMap, take} from 'rxjs/operators';
import { CouponsListViewModel } from 'src/app/services/autogen/Dictionaries';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { Dayjs } from 'dayjs';
import {WlResult} from '../../../../models/common/results/wl-result';
import {GaMessagingService, GaPagingForm, isNullOrUndefined} from '@koddington/ga-common';
import {
  CouponActiveOrNotDropdownStrategy
} from 'src/app/modules/shared/common/enum-dropdown/strategies/coupon-active-or-not-dropdown-strategy';
import {StrictFormControl} from '@koddington/ga-common';
import { CouponActiveOrNotStatus } from 'src/app/services/autogen/Dictionaries';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import {Observable} from 'rxjs';
import {CouponsListComponentViewModel} from '../../../../models/coupons/coupon-list-component-view-model';
import { CouponDropdownFilterForm } from 'src/app/services/autogen/Dictionaries';

@Component({
  selector: 'app-coupons-list',
  templateUrl: './coupons-list.component.html'
})
export class CouponsListComponent implements OnInit, OnDestroy {

  private loading = false;
  private model: CouponsListComponentViewModel = new CouponsListComponentViewModel();
  private pageResult: WlResult<CouponsListViewModel>;

  constructor(private readonly _couponsService: CouponsService,
              private readonly _messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly _router: Router,
              public readonly couponDropdownStatusStrategy: CouponActiveOrNotDropdownStrategy) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      map(value => this.initModel(value)),
      map(() => this.createForm()),
      switchMap((value) => this.loadForDropdown(value)),
    ).subscribe();
  }

  public ngOnDestroy(): void {
  }

  get viewModel(): CouponsListViewModel {
    return this.pageResult.result;
  }

  isNewItem(date: Dayjs): string {
    return date > this.pageResult.result.lastSyncDate ? 'text-info' : '';
  }

  pageChange(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.navigateToParam();
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get isPagingNeeded(): boolean {
    return !isNullOrUndefined(this.viewModel);
  }

  get couponStatus(): StrictFormControl<CouponActiveOrNotStatus> {
    return this.model.CouponStatus;
  }

  public findChosenCouponType(): void {

    this.navigateToParam();
  }

  private navigateToParam(): void {
    const params: Params = {
      CouponStatus: this.model.CouponStatus.hasStrictValue ? this.model.CouponStatus.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30

    };

    this.navigation.search(this.activeRoute, params);
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.CouponStatus.strictValue = !isNullOrUndefined(params['CouponStatus']) ?
      Number(params['CouponStatus']) : null;

  }

  private loadForDropdown(form: CouponDropdownFilterForm): Observable<WlResult<CouponsListViewModel>> {
    this.loading = true;
    return this._couponsService.couponsList(form)
      .pipe(
        take(1),
        filter(res => !this._messaging.tryShowError(res)),
        map(value => this.pageResult = value),
        finalize(() => this.loading = false));
  }

  private createForm(): CouponDropdownFilterForm {
    const form = new CouponDropdownFilterForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : 30;
    form.couponStatus = this.model.CouponStatus.strictValue;

    return form;
  }
}
