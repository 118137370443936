import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    formatDateTime,
    GaMessagingService, GaPagedResult, GaPagingForm, GaSearchManagementStateService, GaTableCellTemplate, GaTableData, isNullOrUndefined
} from '@koddington/ga-common';
import { Router } from '@angular/router';
import { filter, finalize, take } from 'rxjs/operators';
import { MockRateRaceResultsPagingFormViewModel, MockRateRaceResultsPagingFormViewModelValidator, RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { MockRateRaceResultsPagingForm } from 'src/app/services/autogen/BattlePass';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MockRateRaceResultsListModel } from 'src/app/services/autogen/BattlePass';
import { WlDefaultPagingParams } from '../../../../consts/wl-default-paging-params';
import { WlBlockUiService } from '../../../shared/services/wl-block-ui/wl-block-ui.service';

@UntilDestroy()
@Component({
    selector: 'app-rate-race-results-list',
    templateUrl: './rate-race-results-list.component.html',
    providers: [GaSearchManagementStateService, MockRateRaceResultsPagingFormViewModelValidator]
})
export class RateRaceResultsListComponent implements OnInit {
    @ViewChild('elemActions') elemActions: TemplateRef<any>;

    public tableData: GaTableData<MockRateRaceResultsListModel>;
    public pagedResult: GaPagedResult<MockRateRaceResultsListModel>;

    public pagingViewModel = new MockRateRaceResultsPagingFormViewModel(WlDefaultPagingParams);

    constructor(private readonly messaging: GaMessagingService,
                private readonly router: Router,
                private readonly rateRaceService: RateRaceResultsService,
                private readonly searchService: GaSearchManagementStateService<GaPagedResult<MockRateRaceResultsListModel>>,
                private readonly validator: MockRateRaceResultsPagingFormViewModelValidator,
                private readonly blockUi: WlBlockUiService) {
    }

    public ngOnInit(): void {
        this.searchService.init(u => this.rateRaceService.list(u as MockRateRaceResultsPagingForm), this.pagingViewModel);
        this.searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pagedResult = res;
            this.mapTableData(res.results);
            this.searchService.loadingStatus = false;
        });
    }

    public search(): void {
        if (this.validator.isNotValid(this.pagingViewModel)) {
            return;
        }
        this.pageFiltered();
    }

    public pageChanged(form: GaPagingForm): void {
        this.searchService.nextSearch(form);
    }

    public create(): void {
        this.router.navigate(['/dashboard/rate-race/main/results/create']).then(() => {
        });
    }

    public edit(id: number): void {
        this.router.navigate(['/dashboard/rate-race/main/results/update', id]).then(() => {
        });
    }

    public repeat(id: number): void {
        this.router.navigate(['/dashboard/rate-race/main/results/repeat', id]).then(() => {
        });
    }

    public publish(tableId: number): void {
        this.blockUi.start();

        this.rateRaceService.publish(tableId)
            .pipe(
                take(1),
                filter(res => !this.messaging.tryShowError(res)),
                finalize(() => this.blockUi.stop())
            )
            .subscribe(() => {
                this.messaging.showMessage(`Таблица ${tableId} опубликована.`);
                this.pagingViewModel = new MockRateRaceResultsPagingFormViewModel(WlDefaultPagingParams);
                this.pageFiltered();
            });
    }

    private pageFiltered(): void {
        this.searchService.nextSearch(null, true);
    }

    private mapTableData(results: MockRateRaceResultsListModel[]) {
        this.tableData = new GaTableData<MockRateRaceResultsListModel>()
            .addSimpleColumn(u => u.id, {title: 'Id турнира', widthSize: 100})
            .addSimpleColumn(u => u.name, {title: 'Название', widthSize: 500})
            .addSimpleColumn(u => u.battlePassSeasonId, {title: 'Id сезона БП', widthSize: 100})
            .addSimpleColumn(u => formatDateTime(u.createdAt), {title: 'Создано', widthSize: 150})
            .addSimpleColumn(u => formatDateTime(u.updatedAt), {title: 'Изменено', widthSize: 150})
            .addSimpleColumn(u => formatDateTime(u.publishedAt), {title: 'Опубликовано', widthSize: 150})
            .addSimpleColumn(u => formatDateTime(u.handledByBattlePassAt), {title: 'Обработано БП', widthSize: 150})
            .addTemplateColumn(new GaTableCellTemplate(this.elemActions, (elem) => {
                return {
                    id: elem.id,
                    canPublish: isNullOrUndefined(elem.publishedAt)
                };
            }), {title: 'Действия', widthSize: 500})
            .setData(results);
    }

    get loading(): boolean {
        return this.searchService.loadingStatus;
    }
}
