<div>
  <label>Событие об изменении пользовательской информации (clients_change)</label>
  <div>
    <div class="row">
      <app-strict-input-number class="col-2"
                               placeholder="Id"
                               [control]="emitGroupEventModel.userId">
      </app-strict-input-number>
      <strict-date-time-picker class="col-2"
                            placeholder="Дата регистрации клиента"
                            [control]="emitGroupEventModel.registrationDate">
      </strict-date-time-picker>
      <app-strict-dropdown class="col-2"
                         [control]="emitGroupEventModel.registrationSource"
                         [strategy]="strategy"
                         [options]="{placeholder: 'Источник регистрации'}">
      </app-strict-dropdown>
      <app-strict-autocomplete class="col-2"
                               placeholder="Группы безопасности"
                               [strategy]="securityGroupStrategy"
                               [control]="emitGroupEventModel.securityGroup">
      </app-strict-autocomplete>
      <app-strict-input-text class="col-3"
                             placeholder="Электронная почта"
                             [control]="emitGroupEventModel.email">
      </app-strict-input-text>
    </div>
    <div class="row">
      <app-strict-input-text class="col-2"
                             [control]="emitGroupEventModel.phoneNumber"
                             [placeholder]="'Номер телефона'">
      </app-strict-input-text>
      <app-strict-input-text class="col-3"
                             [control]="emitGroupEventModel.clientName"
                             placeholder="Имя">
      </app-strict-input-text>
      <app-strict-input-number class="col-3"
                               placeholder="Статус видеоидентификации"
                               [control]="emitGroupEventModel.videoIdentState">
      </app-strict-input-number>
    </div>
    <div class="row">
      <app-strict-input-boolean class="col-2"
                                label="Ограничение на email"
                                [control]="emitGroupEventModel.emailRestricted">
      </app-strict-input-boolean>
      <app-strict-input-boolean class="col-2"
                                label="Ограничение на СМС"
                                [control]="emitGroupEventModel.smsRestricted">
      </app-strict-input-boolean>
    </div>
    <div class="row">
      <strict-date-time-picker class="col-2"
                            [control]="emitGroupEventModel.emailRestrictionDate"
                            [placeholder]="'Дата ограничения по почте'">

      </strict-date-time-picker>
      <strict-date-time-picker class="col-2"
                            [control]="emitGroupEventModel.smsRestrictionDate"
                            [placeholder]="'Дата ограничения по СМС'">

      </strict-date-time-picker>
      <strict-date-time-picker class="col-3 timeline-marker"
                            [control]="emitGroupEventModel.operationDate"
                            [placeholder]="'Дата события'">

      </strict-date-time-picker>
    </div>
    <button class="btn btn-primary mt-2" (click)="emitChangeInformationEvent()">Сохранить</button>
  </div>
</div>
