import { Component, OnInit } from '@angular/core';
import { EventsService, RegistrationStateFormViewModel, RegistrationStateFormViewModelValidator } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { RegistrationStateForm } from 'src/app/services/autogen/Events';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';

@Component({
    selector: 'app-add-registration-state-changes',
    templateUrl: './add-registration-state-changes.component.html'
})
export class AddRegistrationStateChangesComponent implements OnInit {
    protected readonly model = new RegistrationStateFormViewModel();
    private readonly validator = new RegistrationStateFormViewModelValidator();

    constructor(private readonly service: EventsService,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
    }

    protected add(): void {
        if (this.validator.isNotValid(this.model)) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(RegistrationStateForm, this.model);
        this.service
            .addStateChanges(form)
            .pipe(
                take(1),
                filter(u => !this.messaging.tryShowError(u))
            )
            .subscribe(() => this.messaging.showMessage('Событие добавлено'));
    }
}
