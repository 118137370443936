import { Component, OnInit } from '@angular/core';
import { GaMessagingService, GaPanelGridConfig } from '@koddington/ga-common';
import { take } from 'rxjs/operators';
import { ResetIssuedFreebetFormViewModel, ResetIssuedFreebetFormViewModelValidator } from 'src/app/services/autogen/Events';
import { FreebetsService } from '../../../../services/autogen/Events';

@Component({
    selector: 'app-reset-freebet',
    templateUrl: './reset-freebet.component.html',
    providers: [ResetIssuedFreebetFormViewModelValidator]
})
export class ResetFreebetComponent implements OnInit {
    protected gridConfig: GaPanelGridConfig;
    protected viewModel = new ResetIssuedFreebetFormViewModel();

    constructor(private readonly service: FreebetsService,
                private readonly messaging: GaMessagingService,
                private readonly validator: ResetIssuedFreebetFormViewModelValidator) {
    }

    ngOnInit(): void {
        this.init();
    }

    public reset(): void {
        if (this.validator.validate(this.viewModel).length !== 0) {
            return;
        }

        this.service.reset(this.viewModel.toForm())
            .pipe(
                take(1)
            )
            .subscribe(() => this.messaging.showMessage('Фрибет успешно аннулирован'));
    }

    private init(): void {
        this.gridConfig = new GaPanelGridConfig()
            .addNumbers(this.viewModel.issuedFreebetId, 'ID выданного фрибета')
            .addDateTime(this.viewModel.operationDate, 'Дата операции');
    }
}
