import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatBoolean, formatDateTime, GaPagedResult, GaPagingForm, GaPanelGridConfig, GaTableData } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaSearchManagementStateOptions, GaSearchManagementStateService } from '@koddington/ga-common';
import { UserTriggerProfileSearchModel } from './models/user-trigger-profile-search-model';
import { UserSearchForm, UsersService, WlUserTriggerProfile } from '../../../../services/autogen/Users';
import { WlVideoIdentStatesExtensions } from '../../../../services/autogen/Shared';

@UntilDestroy()
@Component({
    selector: 'app-users-trigger-profile-list',
    templateUrl: './users-trigger-profile-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class UsersTriggerProfileListComponent implements OnInit, OnDestroy {
    public pagingResult: GaPagedResult<WlUserTriggerProfile>;
    public tableData: GaTableData<WlUserTriggerProfile>;
    public searchPanelGrid = new GaPanelGridConfig();
    private searchViewModel = new UserTriggerProfileSearchModel();

    constructor(private readonly _service: UsersService,
                private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlUserTriggerProfile>>) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy() {
    }

    protected pageChanged(form: GaPagingForm): void {
        this._searchManagementState.nextSearch(form);
    }

    protected pageFiltered(): void {
        this._searchManagementState.nextSearch(null, true);
    }

    private init(): void {
        this.searchPanelGrid
            .addNumbers(this.searchViewModel.userId, 'Id пользователя');

        this._searchManagementState.init(
            (form) => this._service.usersTriggerProfileList(form as UserSearchForm),
            this.searchViewModel,
            {
                fnPageParamsSetter: u => u['tab'] = 'UsersTriggerProfile'
            }
        );
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pagingResult = res;
            this.tableData = new GaTableData<WlUserTriggerProfile>()
                .addSimpleColumn(u => u.userId, {title: 'Id пользователя'})
                .addSimpleColumn(u => formatBoolean(u.hasFirstBall), {title: 'Есть признак "Первый мяч"', widthSize: 210})
                .addSimpleColumn(u => u.maxRealMoneyWin, {title: 'Максимальный выигрыш'})
                .addSimpleColumn(u => u.maxRealMoneyBet, {title: 'Максимальная ставка', widthSize: 170})
                .addSimpleColumn(u => u.maxFreeBetWin, {title: 'Максимальный фрибетный выигрыш', widthSize: 270})
                .addSimpleColumn((u) => formatDateTime(u.lastVideoIdentStateChangeDate), { title: 'Дата последней видеоверификации', widthSize: 260 })
                .addSimpleColumn((u) => WlVideoIdentStatesExtensions.format(u.currentVideoIdentState), { title: 'Текущее состояние видеоверификации', widthSize: 160 })
                .addSimpleColumn(u => u.videoIdentFailuresCount, {title: 'Количество неудачных видеоверификаций', widthSize: 300})
                .setData(res.results);
            this._searchManagementState.loadingStatus = false;
        });
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}
