<div class="col-5"><h1>Добавить информацию</h1>
  <div>
    <div class="form-group">
      <app-strict-dropdown
              [control]="userContactInformationType"
              [strategy]="_userContactInformationTypesStrategy"
              [options]="{placeholder: 'Выберите тип данных'}">
      </app-strict-dropdown>
    </div>
    <div class="form-group">
      <app-strict-input-number [control]="userId" placeholder="Id пользователя">
      </app-strict-input-number>
    </div>
    <div class="form-group">
      <app-strict-input-text [control]="contactInfo" placeholder="Номер телефона / Email">
      </app-strict-input-text>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
  </div>
</div>
