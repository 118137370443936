import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaMessagingService, GaPanelGridConfig, GaTableCellTemplate, GaTableData } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import {
    DailyTasksErpService, WlErpDtWinterLeaguePromoApiModel
} from '../../../../services/autogen/DailyTasks';
import { DailyTaskPromoErpViewModel } from '../../view-models/daily-task-promo-erp-view.model';
import { DailyTaskPromoErpValidator } from '../../validators/daily-task-promo-erp-validator.service';

@Component({
    selector: 'app-winter-league-erp',
    templateUrl: './winter-league-erp.component.html',
    styleUrls: ['./winter-league-erp.component.scss']
})
export class WinterLeagueErpComponent implements OnInit {
    @ViewChild('daysShow') daysShow: TemplateRef<any>;

    protected filterViewModel = new DailyTaskPromoErpViewModel();
    protected filterInputGrid = new GaPanelGridConfig();

    public tableData: GaTableData<WlErpDtWinterLeaguePromoApiModel>;

    private readonly _validator = new DailyTaskPromoErpValidator();

    constructor(private readonly _service: DailyTasksErpService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.filterInputGrid
            .addNumbers(this.filterViewModel.userId, 'ID юзера')
            .addNumbers(this.filterViewModel.promoId, 'ID акции');
    }

    public load(): void {
        if (this._validator.validate(this.filterViewModel).length > 0) {
            return;
        }

        this._service.getWinterLeagueProfile(this.filterViewModel.userId.strictValue, this.filterViewModel.promoId.strictValue).pipe(
            take(1),
            filter(res => !this._messaging.tryShowError(res))
        ).subscribe(res => {
            this.mapToTable(res.result);
        });
    }

    private mapToTable(result: WlErpDtWinterLeaguePromoApiModel): void {
        this.tableData = new GaTableData<WlErpDtWinterLeaguePromoApiModel>()
            .setData([result])
            .addSimpleColumn((elem) => elem.id, {title: 'Id', widthSize: 60})
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 160})
            .addSimpleColumn((elem) => formatDateTime(elem.startDate), {title: 'Дата начала', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.technicalEndDate), {title: 'Дата тех.завершения', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.endDate), {title: 'Дата завершения', widthSize: 150})
            .addTemplateColumn(new GaTableCellTemplate(this.daysShow, (elem) => {
                return {
                    days: elem.days
                };
            }), {title: 'Дни', widthSize: 900});
    }
}
