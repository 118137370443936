import {Component, OnInit} from '@angular/core';
import { MediaService } from 'src/app/services/autogen/Shared';
import {MediaPagingViewModel} from '../../../../models/media/view/media-paging-view-model';
import {WlResult} from '../../../../models/common/results/wl-result';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import { WlMockMediaSource } from 'src/app/services/autogen/Shared';
import { MediaSourceListFilterForm } from 'src/app/services/autogen/Shared';
import {DateOrderDropdownStrategy} from '../../../shared/common/enum-dropdown/strategies/date-order.dropdown-strategy';
import { DateOrder } from 'src/app/services/autogen/Shared';
import {Observable} from 'rxjs';
import {StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-media-source-list',
  templateUrl: './media-source-list.component.html',
  styleUrls: ['./media-source-list.component.css']
})
export class MediaSourceListComponent implements OnInit {


  public viewModel: MediaPagingViewModel = new MediaPagingViewModel();
  public result: WlResult<GaPagedResult<WlMockMediaSource>>;

  private _isLoading = false;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly service: MediaService,
              public readonly strategy: DateOrderDropdownStrategy) {
  }

  ngOnInit() {

    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public isLoading(): boolean {
    return this._isLoading;
  }
    public list(): WlMockMediaSource[] {
    return this.result.result.results;
  }

  get dateStatus(): StrictFormControl<DateOrder> {
    return this.viewModel.dateOrder;
  }
  get idsStatus(): StrictFormControl<DateOrder> {
    return this.viewModel.idsOrder;
  }

  public isPagingNeeded(): boolean {
    return !isNullOrUndefined(this.viewModel);
  }

  public search(): void {
    this.viewModel.offset.strictValue = 0;
    this.navigateWithParameters();
  }

  public pageChanged(form: MediaSourceListFilterForm): void {
    this.viewModel.count.strictValue = form.count;
    this.viewModel.offset.strictValue = form.offset;

    this.navigateWithParameters();
  }

  private load(form: MediaSourceListFilterForm): Observable<WlResult<GaPagedResult<WlMockMediaSource>>> {
    this._isLoading = true;

    return this.service.sourceList(form).pipe(
      take(1),
      finalize(() => this._isLoading = false),
      untilDestroyed(this)
    );
  }

  pageChange(form: MediaSourceListFilterForm): void {
    this.viewModel.offset.strictValue = form.offset;
    this.navigateWithParameters();
  }

  private createForm(): MediaSourceListFilterForm {
    const form = new MediaSourceListFilterForm();

    form.offset = this.viewModel.offset.hasStrictValue ? this.viewModel.offset.strictValue : 0;
    form.count = this.viewModel.count.hasStrictValue ? this.viewModel.count.strictValue : 30;
    form.idsOrder = this.viewModel.idsOrder.hasStrictValue ? this.viewModel.idsOrder.strictValue : null;
    form.dateOrder = this.viewModel.dateOrder.hasStrictValue ? this.viewModel.dateOrder.strictValue : null;

    return form;
  }

  private initModel(params: Params): void {
    this.viewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.viewModel.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.viewModel.idsOrder.strictValue = !isNullOrUndefined(params['idsOrder']) ?
      params['idsOrder'] : null;
    this.viewModel.dateOrder.strictValue = !isNullOrUndefined(params['dateOrder']) ?
      params['dateOrder'] : null;
  }

  private navigateWithParameters(): void {
    const params: Params = {
      offset: this.viewModel.offset.hasStrictValue ? this.viewModel.offset.strictValue : 0,
      tab: 'SourcesList',
      idsOrder: this.viewModel.idsOrder.hasStrictValue ? this.viewModel.idsOrder.strictValue : null,
      dateOrder: this.viewModel.dateOrder.hasStrictValue ? this.viewModel.dateOrder.strictValue : null,
      count: this.viewModel.count.hasStrictValue ? this.viewModel.count.strictValue : 30
    };

    this.navigation.search(this.activeRoute, params);
  }
}
