import { AbstractModelValidator } from '@koddington/ga-common';
import { DailyTaskPromoErpViewModel } from '../view-models/daily-task-promo-erp-view.model';

export class DailyTaskPromoErpValidator extends AbstractModelValidator<DailyTaskPromoErpViewModel> {
    constructor(private readonly _validatePromoRequirement: boolean = true) {
        super();

        this.ruleForControl(u => u.userId)
            .required()
            .min(1);

        this.ruleForControl(u => u.promoId)
            .min(1);

        this.ruleForControlIf(u => u.userId, _ => _validatePromoRequirement)
            .required();
    }
}
