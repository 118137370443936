import { Component, Input, OnInit } from '@angular/core';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {
    BattlePassRequestAccrualRewardViewModel
} from '../../../../models/events/components-models/battle-pass-request-accrual-reward-view-model';
import { WlBattlePassRequestAccrualRewardModel } from 'src/app/services/autogen/BattlePass';
import { ResultAccrualRewardForm } from 'src/app/services/autogen/BattlePass';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BattlePassAccrualRewardValidator } from '../../../events/validators/battle-pass-accrual-reward-validator';
import { EnumExtensions, GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';
import {
    WlRewardExternalResponseTypesStrategy
} from '../../../shared/common/enum-dropdown/strategies/wl-reward-external-response-types-strategy';
import {
    WlBattlePassRewardProcessingStatesStrategy
} from '../../../shared/common/enum-dropdown/strategies/wl-battle-pass-reward-processing-states-strategy';
import {
    WlBattlePassRewardResponseErrorCodes,
    WlBattlePassRewardResponseErrorCodesExtensions
} from '../../../../enums/wl-battle-pass-reward-response-error-codes';

@UntilDestroy()
@Component({
    selector: 'app-battle-pass-accrual-reward-item',
    templateUrl: './battle-pass-accrual-reward-item.component.html'
})
export class BattlePassAccrualRewardItemComponent implements OnInit {

    @Input()
    public item: WlBattlePassRequestAccrualRewardModel;
    public viewModel = new BattlePassRequestAccrualRewardViewModel;
    public responseTypesStrategy: WlRewardExternalResponseTypesStrategy;
    public responseCodeStrategy: WlBattlePassRewardProcessingStatesStrategy;
    private validator: BattlePassAccrualRewardValidator;

    constructor(private readonly battlePassService: BattlePassService,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public sendResponse(): void {
        const errors = this.validator.validate(this.viewModel);
        if (errors.length > 0) {
            return;
        }

        const form = new ResultAccrualRewardForm();

        form.id = this.viewModel.id;
        form.status = this.viewModel.status.strictValue;
        form.comment = this.viewModel.comment.strictValue;

        this.battlePassService.sendResponse(form)
            .pipe(
                filter(u => !this.messaging.tryShowError(u)),
                untilDestroyed(this))
            .subscribe(_ => this.messaging.showMessage('Запрос успешно отправлен'));
    }

    private loadModel(): void {
        this.viewModel.id = this.item.id;
        this.viewModel.requestId = this.item.requestId;
        this.viewModel.tradeLinkUrl = this.item.tradeLinkUrl;
        this.viewModel.itemId = this.item.itemId;
        this.viewModel.hashName = this.item.hashName;
        this.viewModel.operation = this.item.operation;
        this.viewModel.status.strictValue = isNullOrUndefined(this.item.status) ? null : Number(this.item.status);

        if (!!this.item.comment) {
            const errorCode = EnumExtensions.toArray(WlBattlePassRewardResponseErrorCodes)
                                            .find(u => WlBattlePassRewardResponseErrorCodesExtensions.toRabbitMessage(u) === this.item.comment);
            this.viewModel.errorCode.strictValue = errorCode;
            this.onErrorCodeChange(errorCode);
        }

        this.viewModel.comment.strictValue = this.item.comment;
    }

    private init(): void {
        this.responseTypesStrategy = new WlRewardExternalResponseTypesStrategy();
        this.responseCodeStrategy = new WlBattlePassRewardProcessingStatesStrategy();
        this.validator = new BattlePassAccrualRewardValidator();
        this.loadModel();

        this.viewModel.errorCode.valueChanges.pipe(untilDestroyed(this)).subscribe(res => this.onErrorCodeChange(res));
    }

    private onErrorCodeChange(value: WlBattlePassRewardResponseErrorCodes) {
        if (isNullOrUndefined(value)) {
            this.viewModel.comment.strictValue = null;
            this.viewModel.comment.enable();
            return;
        }

        this.viewModel.comment.strictValue = WlBattlePassRewardResponseErrorCodesExtensions.toRabbitMessage(value);
        this.viewModel.comment.disable();
    }
}
