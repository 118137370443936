import {Component, OnInit} from '@angular/core';
import { UserMechanicsProcessingService } from 'src/app/services/autogen/UserMechanics';
import {UserMechanicsNavigationService} from '../services/user-mechanics-navigation.service';
import {ActivatedRoute} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WinlineMechanicTriggeringStatus } from 'src/app/services/autogen/UserMechanics';
import {MechanicTriggeringRequestStatusDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/mechanic-triggering-request-status-dropdown-strategy.service';
import {MechanicTriggeringRequestViewModelValidator} from '../validators/mechanic-triggering-request-view-model.validator';
import { GaMessagingService, StrictFormControlBuilder } from '@koddington/ga-common';
import { MechanicTriggeringRequestStrictViewModel } from '../../../models/mechanics/mechanic-triggering-request-strict-view.model';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanic-triggering-requests',
  templateUrl: './user-mechanic-triggering-requests.component.html',
  styleUrls: ['./user-mechanic-triggering-requests.component.css']
})
export class UserMechanicTriggeringRequestsComponent implements OnInit {

  public models: MechanicTriggeringRequestStrictViewModel[] = [];
  public allStatesControl = StrictFormControlBuilder.buildNullNumber<WinlineMechanicTriggeringStatus>();

  private _sequenceId: number;

  constructor(public readonly strategy: MechanicTriggeringRequestStatusDropdownStrategy,
              private readonly processingService: UserMechanicsProcessingService,
              private readonly validator: MechanicTriggeringRequestViewModelValidator,
              private readonly navigation: UserMechanicsNavigationService,
              private readonly route: ActivatedRoute,
              private readonly messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this._sequenceId = Number(routeParams.get('id'));
    this.load();
  }

  public setStatusForAll(): void {
    if (!this.allStatesControl.hasStrictValue) {
      this.messages.showMessage('Выберите статус');
      return;
    }

    this.processingService.rawSetStatusForAllTriggeringRequest(this._sequenceId, this.allStatesControl.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  public saveNewStatus(model: MechanicTriggeringRequestStrictViewModel): void {
    const errors = this.validator.validate(model);
    if (errors.length > 0) {
      return;
    }

    this.processingService.setStatusForTriggeringRequest(model.request.id, model.newStatus.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  private load(): void {
    this.processingService.getTriggeringRequests(this._sequenceId)
      .pipe(
        take(1),
        filter((res) => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.models = value.result.map(u => {
          return {
            request: u,
            newStatus: StrictFormControlBuilder.buildNullNumber<WinlineMechanicTriggeringStatus>().withValue(u.processingStatus)
          };
        });
      });
  }
}
