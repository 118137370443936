import {Component, OnInit} from '@angular/core';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { WlUserMechanicLight } from 'src/app/services/autogen/UserMechanics';
import { UsersMechanicsFilterForm } from 'src/app/services/autogen/UserMechanics';
import { UserMechanicsService } from 'src/app/services/autogen/UserMechanics';
import {UserMechanicsListViewModel} from '../../../models/mechanics/user-mechanics-list-view-model';
import {filter, finalize, map, switchMap, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserMechanicsListValidator} from '../validators/user-mechanics-list-validator';
import {MechanicStatesDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/mechanic-states-dropdown-strategy';
import { WlMechanicsStates } from 'src/app/services/autogen/UserMechanics';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import {GaMessagingService, GaPagingForm, isNullOrUndefined} from '@koddington/ga-common';
import {Observable} from 'rxjs';
import {StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanics-list',
  templateUrl: './user-mechanics-list.component.html',
  styleUrls: ['./user-mechanics-list.component.css']
})
export class UserMechanicsListComponent implements OnInit {

  public loading: boolean;
  private result: WlFilteredPagedResult<WlUserMechanicLight, UsersMechanicsFilterForm>;
  private model: UserMechanicsListViewModel = new UserMechanicsListViewModel();

  constructor(private readonly service: UserMechanicsService,
              private readonly validator: UserMechanicsListValidator,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly messaging: GaMessagingService,
              public readonly mechanicStatusStrategy: MechanicStatesDropdownStrategy) {
  }

  get userMechanics(): WlUserMechanicLight[] {
    return this.result.results;
  }

  get paging(): UsersMechanicsFilterForm {
    return this.result.paging;
  }

  get currentResult(): WlFilteredPagedResult<WlUserMechanicLight, UsersMechanicsFilterForm> {
    return this.result;
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get mechanicStatus(): StrictFormControl<WlMechanicsStates> {
    return this.model.mechanicStatus;
  }

  public isDisable(state: WlMechanicsStates): boolean {
    return state !== WlMechanicsStates.Enabled;
  }

  public ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      map(value => this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public findMechanicsForUser(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    this.navigateToParam();
  }

  public changePage(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.navigateToParam();
  }

  public disable(mechanicId: number): void {
    this.service.disableUserMechanicById(mechanicId)
      .pipe(
        take(1),
        filter(value => !this.messaging.tryShowError(value))
      )
      .subscribe(value => {
        this.messaging.showMessage('Механика отключена');
        this.navigateToParam();
      });

  }

  private load(form: UsersMechanicsFilterForm): Observable<WlFilteredPagedResult<WlUserMechanicLight, UsersMechanicsFilterForm>> {
    this.loading = true;
    return this.service.list(form)
      .pipe(
        take(1),
        finalize(() => this.loading = false));
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
    this.model.mechanicStatus.strictValue = !isNullOrUndefined(params['status']) ?
      params['status'] : null;
  }

  private createForm(): UsersMechanicsFilterForm {
    const form = new UsersMechanicsFilterForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : 30;
    form.userId = this.model.userId.strictValue;
    form.mechanicState = this.model.mechanicStatus.strictValue;

    return form;
  }

  private navigateToParam(): void {
    const params: Params = {
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      status: this.model.mechanicStatus.hasStrictValue ? this.model.mechanicStatus.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30
    };

    this.navigation.search(this.activeRoute, params);
  }
}
