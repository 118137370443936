<h1>Запросы на подключение механик</h1>
<table class="table mt-3">
  <tr class="d-flex">
    <td class="col-1">
      Id пользователя
    </td>
    <td class="col-1">
      ID триггерного события
    </td>
    <td class="col-1">
      ID начисленной механики
    </td>
    <td class="col-1">
      Механика
    </td>
    <td class="col-1">
      Идентификатор механики на стороне винлайна
    </td>
    <td class="col-1">
      Кол-во дней ожидания, срок жизни условной механики, для безусловной механики нужно передать 0
    </td>
    <td class="col-1">
      ID фрибета/купона
    </td>
    <td class="col-1">
      Сумма ставки или депозита для срабатывания, или лимит для % от депозита(0 - для любого депозита, если нет
      ограничения, для мячей будет 0)
    </td>
    <td class="col-1">
      Сумма фрибета или сумма денег(для безусловной механики 9 “Деньги”) или % для механики 8 “Бонус % за депозит”
    </td>
    <td class="col-1">
      Кол-во дней действия выданного бонуса
    </td>
    <td class="col-1">
      Признак торчок/отдыхающий
    </td>
    <td class="col-1">
      <app-strict-dropdown
              [control]="allStatesControl"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="setStatusForAll()">Сохранить для всех</button>
    </td>
  </tr>

  <tr *ngFor="let model of models" class="d-flex">
    <td class="col-1">
      {{model.request.userId}}
    </td>
    <td class="col-1">
      {{model.request.triggerSequenceEventId}}
    </td>
    <td class="col-1">
      {{model.request.userMechanicId}}
    </td>
    <td class="request-name col-1">
      {{model.request.mechanic?.name}}
    </td>
    <td class="col-1">
      {{model.request.mechanicExternalId}}
    </td>
    <td class="col-1">
      {{model.request.mechanicLifetime}}
    </td>
    <td class="col-1">
      {{model.request.bonusId}}
    </td>
    <td class="col-1">
      {{model.request.targetParameter}}
    </td>
    <td class="col-1">
      {{model.request.bonusValue}}
    </td>
    <td class="col-1">
      {{model.request.bonusLifetime}}
    </td>
    <td class="col-1">
      {{model.request.userType}}
    </td>
    <td class="col-1">
      <app-strict-dropdown
              [control]="model.newStatus"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="saveNewStatus(model)">Сохранить</button>
    </td>
  </tr>
</table>
