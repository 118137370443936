import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { UpdateMockPhoneSettingsForm, WlMockPhone } from 'src/app/services/autogen/SmsGate';
import {UpdatePhoneSettingsViewModel} from './models/update-phone-settings.view-model';
import {SmsGateErrorCodesDropdownStrategy} from '../../../../shared/common/enum-dropdown/strategies/sms-gate-error-codes.dropdown-strategy';
import {
  SmsTrafficConversionCodesDropdownStrategy
} from '../../../../shared/common/enum-dropdown/strategies/sms-traffic-conversion-codes.dropdown-strategy';
import {
  MtsCommunicatorConversionCodesDropdownStrategy
} from '../../../../shared/common/enum-dropdown/strategies/mts-communicator-conversion-codes.dropdown-strategy';
import {GaMessagingService, isNullOrUndefined} from '@koddington/ga-common';
import { MockPhonesService } from 'src/app/services/autogen/SmsGate';
import {filter, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MtsExoConversionCodesDropdownStrategy } from '../../../../shared/common/enum-dropdown/strategies/mts-exo-conversion-codes-dropdown-strategy';

@UntilDestroy()
@Component({
  selector: 'app-phones-list-item',
  templateUrl: './phones-list-item.component.html'
})
export class PhonesListItemComponent implements OnInit, OnDestroy {

  @Input()
  public phone: WlMockPhone;

  public viewModel: UpdatePhoneSettingsViewModel;

  constructor(public readonly smsGateErrorStrategy: SmsGateErrorCodesDropdownStrategy,
              public readonly smsTrafficConversionCodeStrategy: SmsTrafficConversionCodesDropdownStrategy,
              public readonly mtsCommunicatorConversionCodeStrategy: MtsCommunicatorConversionCodesDropdownStrategy,
              public readonly mtsExoConversionCodeStrategy: MtsExoConversionCodesDropdownStrategy,
              private readonly _apiClient: MockPhonesService,
              private readonly _messages: GaMessagingService) {
  }

  public get loading(): boolean {
    return isNullOrUndefined(this.viewModel);
  }

  public ngOnInit(): void {
    this.viewModel = new UpdatePhoneSettingsViewModel(this.phone);
  }

  public ngOnDestroy(): void {
    this.viewModel = null;
  }

  public saveChanges(): void {
    const form = new UpdateMockPhoneSettingsForm();
    form.id = this.viewModel.id;
    form.defaultMtsSendingCode = this.viewModel.defaultMtsSendingCode.strictValue;
    form.defaultSmsTrafficSendingCode = this.viewModel.defaultSmsTrafficSendingCode.strictValue;
    form.defaultMtsExoSendingCode = this.viewModel.defaultMtsExoSendingCode.strictValue;
    form.defaultMtsCommunicatorConversionCode = this.viewModel.defaultMtsCommunicatorConversionCode.strictValue;
    form.defaultSmsTrafficConversionCode = this.viewModel.defaultSmsTrafficConversionCode.strictValue;
    form.defaultMtsExoConversionCode = this.viewModel.defaultMtsExoConversionCodes.strictValue;

    this._apiClient.changePhoneState(form)
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messages.showMessage('Изменения сохранены');
      });
  }
}
