import { DropdownItem, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlRegistrationPlatforms, WlRegistrationPlatformsExtensions } from 'src/app/services/autogen/Events';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class RegistrationPlatformStrategy implements IDropdownStrategy<WlRegistrationPlatforms> {
    private collection = [
        WlRegistrationPlatforms.Web,
        WlRegistrationPlatforms.Ios,
        WlRegistrationPlatforms.Android,
        WlRegistrationPlatforms.Mobile,
        WlRegistrationPlatforms.ExternalSourceCupis,
        WlRegistrationPlatforms.NewWeb,
    ];

    constructor() {}

    public getEntities(): Observable<Array<WlRegistrationPlatforms>> {
        return of(this.collection);
    }

    public map(model: WlRegistrationPlatforms): DropdownItem<WlRegistrationPlatforms> {
        if (isNullOrUndefined(model)) {
            return undefined;
        }
        const item = new DropdownItem<WlRegistrationPlatforms>();
        item.id = model.toString();
        item.entity = model;
        item.title = WlRegistrationPlatformsExtensions.format(model);

        return item;
    }
}
