<div>
    <h3>Добавление/изменение приглашения</h3>
    <div class="row">
        <app-strict-input-number class="col-2"
                                 [min]="1"
                                 [control]="invitationViewModel.tournamentId"
                                 [placeholder]="'Id турнира'"
                                 title="Id турнира"
        ></app-strict-input-number>
        <app-strict-input-number class="col-2"
                                 [min]="1"
                                 [control]="invitationViewModel.userId"
                                 [placeholder]="'Id юзера'"
                                 title="Id юзера"
        ></app-strict-input-number>
        <app-strict-input-boolean class="col-2"
                                  [control]="invitationViewModel.isDeleted"
                                  title="Отозвано"
        ></app-strict-input-boolean>
        <div class="col-2">
            <button class="btn btn-primary ga-strict-input-label-margin-top" (click)="setInvitation(invitationViewModel)">Сохранить</button>
        </div>
    </div>

    <h3>Приглашения в турниры</h3>
    <div *ngIf="loading">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!loading">
        <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
        <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
</div>

<ng-template #templateActions let-rawViewModel="viewModel">
    <ng-template [ngIf]="asFormViewModel(rawViewModel)" let-viewModel="ngIf">
        <div class="row">
            <app-strict-input-boolean class="col"
                                      [control]="viewModel.isDeleted"
                                      [label]="'Отозвано'"
            ></app-strict-input-boolean>
            <button class="btn btn-primary col" (click)="setInvitation(viewModel)">Сохранить</button>
        </div>
    </ng-template>
</ng-template>
