<h1>Механики пользователей</h1>
<div>
  <div class="row">
    <div class="form-group col-3">
      <app-strict-input-number
              [control]="userId"
              placeholder="Id пользователя"
              title="Id пользователя">
      </app-strict-input-number>
    </div>
    <div class="form-group col-3">
      <app-strict-dropdown
                           [control]="mechanicStatus"
                           [strategy]="mechanicStatusStrategy"
                           [options]="{title: 'Статус механики', placeholder: 'Не выбран'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="form-group col-3">
    <button class="btn btn-primary" (click)="findMechanicsForUser()">Поиск</button>
  </div>
</div>
<div *ngIf="isLoading" class="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading">
  <table class="table mt-3">
    <tr class="d-flex">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Id механики
      </td>
      <td class="col-1">
        Id пользователя
      </td>
      <td class="col-1">
        Имя механики
      </td>
      <td class="col-1">
        Тип механики
      </td>
      <td class="col-1">
        Дата подключения механики
      </td>
      <td class="col-1">
        Статус механики
      </td>
      <td class="col-1">
        Статус бонуса
      </td>
      <td class="col-1">
        ID события в цепочке
      </td>
      <td class="col-1">
        ID выданого фрибета
      </td>
      <td class="col-1">
        ID выданого купона
      </td>
      <td class="col-1">
        ID отслеживаемой ставки
      </td>
    </tr>
    <tr *ngFor="let userMechanic of userMechanics" class="d-flex">
      <td class="col-1">
        <a [routerLink]="['/dashboard/mechanics/user-mechanic-details', userMechanic.id]">
          {{userMechanic.id}}
        </a>
      </td>
      <td class="col-1">
        {{userMechanic.mechanic.id}}
        <br>
        <button class="btn btn-primary small-button" (click)="disable(userMechanic.mechanic.id)" [disabled]="isDisable(userMechanic.mechanicState)">Присвоить ошибку</button>
      </td>
      <td class="col-1">
        {{userMechanic.userId}}
      </td>
      <td class="col-1" style="word-break: break-all;">
        {{userMechanic.mechanic.name}}
      </td>
      <td class="col-1">
        {{userMechanic.mechanic.mechanicsType | wlMechanicsTypesTransform}}
      </td>
      <td class="col-1">
        {{userMechanic.enabledAt | moscowDateTime}}
      </td>
      <td class="col-1">
        {{userMechanic.mechanicState | wlMechanicsStatesTransform}}
      </td>
      <td class="col-1">
        {{userMechanic.bonusState | wlMechanicsBonusStatesTransform}}
      </td>
      <td class="col-1">
        {{userMechanic.triggerSequenceEventId}}
      </td>
      <td class="col-1">
        {{userMechanic.issuedFreebetId}}
      </td>
      <td class="col-1">
        {{userMechanic.issuedCouponId}}
      </td>
      <td class="col-1">
        {{userMechanic.betIdUsedForActivation}}
      </td>
    </tr>
  </table>
  <ga-paging [pagedResult]="currentResult" (pageChangedEvent)="changePage($event)"></ga-paging>
</div>
