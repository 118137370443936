import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {take, finalize, map, switchMap, filter} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {SearchNavigationService} from 'src/app/modules/shared/services/search-navigation.service';
import {AddViewModel} from '../push-mock-error-handle/models/push-mock-add-view-model';
import {MockErrorDictionaryModel, MockErrorGCmDictionaryModel} from '../push-mock-error-handle/models/push-mock-error-code';
import {AddErrorByTokenValidator} from '../push-mock-error-handle/validators/push-mock-add-error-token-validators';
import {PushListViewModel} from '../push-mock-list/models/push-mock-list.viewmodel';
import {Observable} from 'rxjs';
import {MockPushHistoryListForm } from '../../../../services/autogen/PushGate';
import {AddErrorByTokenForm} from '../push-mock-error-handle/models/push-mock-add-error-token-form';
import {ApnsResponseStatus} from '../../enums/push-mock-apns-response-enum';
import {
  ApnsResponseStatusDropdownStrategy
} from 'src/app/modules/shared/common/enum-dropdown/strategies/push-mock-apns-response-status-strategy';
import {MockPushApnsErrorHandlerSevice} from '../../api-clients/push-mock-error-handler-apns-client';
import {formatDateTime, GaMessagingService, GaPagedResult, GaTableData, isNullOrUndefined, StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-push-mock-error-handle-apns',
  templateUrl: './push-mock-error-handle-apns.component.html',
  styleUrls: ['./push-mock-error-handle-apns.component.css']
})
export class PushMockErrorHandleApnsComponent implements OnInit {

  public result: MockErrorDictionaryModel[] = [];
  public pagedResult: GaPagedResult<MockErrorDictionaryModel> = new GaPagedResult<MockErrorDictionaryModel>();
  public loading: boolean;
  public model: PushListViewModel = new PushListViewModel();
  private readonly addViewModel: AddViewModel = new AddViewModel();
  private defaultCount = 30;
  private defaultOffset = 0;

  tableData: GaTableData<MockErrorGCmDictionaryModel>;

  constructor(private readonly _httpClient: MockPushApnsErrorHandlerSevice,
              private readonly _navigation: SearchNavigationService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly messaging: GaMessagingService,
              private readonly _validator: AddErrorByTokenValidator,
              public readonly _strategy: ApnsResponseStatusDropdownStrategy) {
  }

  ngOnInit(): void {
    this._activeRoute.queryParams.pipe(
      map((params) => this.initModel(params)),
      map(() => this.createForm()),
      switchMap((paging) => this.load(paging)),
      untilDestroyed(this)
    ).subscribe(data => {
      this.pagedResult = data;
      this.result = data.results;
      this.mapToTable();
    });
  }

  public pageChanged(form: MockPushHistoryListForm): void {
    this.model.offset.strictValue = form.offset;
    this.search();
  }

  private load(form: MockPushHistoryListForm): Observable<GaPagedResult<MockErrorDictionaryModel>> {
    this.loading = true;
    return this._httpClient.getList(form.count, form.offset, form.token).pipe(
      take(1),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    );
  }

  private createForm(): MockPushHistoryListForm {
    const form = new MockPushHistoryListForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount;
    form.token = this.model.token.hasStrictValue ? this.model.token.strictValue : null;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.token.strictValue = !isNullOrUndefined(params['token']) ?
      params['token'] : null;
  }

  public search(): void {
    const params: Params = {
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount,
      token: this.model.token.hasStrictValue ? this.model.token.strictValue : null,
    };
    this._navigation.search(this._activeRoute, params);
  }

  save(): void {
    if (this._validator.validate(this.addViewModel).length === 0) {
      const form = new AddErrorByTokenForm();
      form.errorCode = this.addViewModel.errorCode.strictValue;
      form.token = this.addViewModel.token.strictValue;
      this._httpClient.addErrorByToken(form)
        .pipe(take(1), filter(value => !this.messaging.tryShowError(value)))
        .subscribe(value => {
          value.result === true ? this.messaging.showMessage('Запись успешно добавлена') :
            this.messaging.showMessage('Запись не была добавлена');
          this.search();
        });
    }
  }

  delete(id: number): void {
    this._httpClient.deleteErrorById(id)
      .pipe(take(1), filter(value => !this.messaging.tryShowError(value)))
      .subscribe(value => {
        value.result === true ? this.messaging.showMessage('Запись успешно удалена') :
          this.messaging.showMessage('Удаление записи не произошло');
        this.search();
      });
  }

  public get tokenInput(): StrictFormControl<string> {
    return this.addViewModel.token;
  }

  get apnsResponseStatus(): StrictFormControl<ApnsResponseStatus> {
    return this.addViewModel.errorCode;
  }

  private mapToTable(): void {
    this.tableData = new GaTableData<MockErrorGCmDictionaryModel>()
      .setData(this.result)
      .addSimpleColumn((elem) => elem.id, { title: 'Id' })
      .addSimpleColumn((elem) => elem.token, { title: 'Token' })
      .addSimpleColumn((elem) => elem.errorCode, { title: 'Error code' })
      .addSimpleColumn((elem) => elem.errorDescription, { title: 'Error description' })
      .addSimpleColumn((elem) => formatDateTime(elem.dateTimeCreate), { title: 'Date time created' })
      .addActionColumn(_ => 'Удалить', (elem) => this.delete(elem.id), {title: ''});
  }
}
