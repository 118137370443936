import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    GaMessagingService,
    GaPagedResult,
    GaPagingForm,
    GaSearchManagementStateService,
    GaStrictModelFactory,
    GaTableCellTemplate,
    GaTableData
} from '@koddington/ga-common';
import {
    CustomTournamentService,
    CustomTournamentsInvitationFormViewModelValidator,
    CustomTournamentsTablePagingFormViewModel,
    ICustomTournamentsTablePagingForm
} from 'src/app/services/autogen/CustomTournaments';
import { take, filter } from 'rxjs/operators';
import { CustomTournamentsTablePagingForm } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentInvitationModel } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentsInvitationFormViewModel } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentsInvitationForm } from 'src/app/services/autogen/CustomTournaments';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlDefaultPagingParams } from '../../../../consts/wl-default-paging-params';

@UntilDestroy()
@Component({
    selector: 'app-custom-tournament-invitations',
    templateUrl: './custom-tournament-invitations.component.html',
    providers: [GaSearchManagementStateService]
})
export class CustomTournamentInvitationsComponent implements OnInit {
    @ViewChild('templateActions') templateActions: TemplateRef<any>;

    public pagedResult: GaPagedResult<CustomTournamentInvitationModel>;
    public tableData: GaTableData<CustomTournamentsInvitationFormViewModel>;

    public invitationViewModel = new CustomTournamentsInvitationFormViewModel();
    public validator = new CustomTournamentsInvitationFormViewModelValidator();

    private filterViewModel = new CustomTournamentsTablePagingFormViewModel(WlDefaultPagingParams as ICustomTournamentsTablePagingForm);

    constructor(private readonly tournaments: CustomTournamentService,
                private readonly messaging: GaMessagingService,
                private readonly searchService: GaSearchManagementStateService<GaPagedResult<CustomTournamentInvitationModel>>) {
    }

    public ngOnInit(): void {
        this.searchService.init(u => this.tournaments.listInvitations(u as CustomTournamentsTablePagingForm), this.filterViewModel);
        this.searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pagedResult = res;
            this.mapToTable(res.results);
            this.searchService.loadingStatus = false;
        });
    }

    public pageChanged(form: GaPagingForm): void {
        this.searchService.nextSearch(form);
    }

    public pageFiltered(): void {
        this.searchService.nextSearch(null, true);
    }

    public asFormViewModel(viewModel: any): CustomTournamentsInvitationFormViewModel {
        return viewModel as CustomTournamentsInvitationFormViewModel;
    }

    public setInvitation(viewModel: CustomTournamentsInvitationFormViewModel): void {
        if (!this.validator.isValid(viewModel)) {
            return;
        }
        const form = GaStrictModelFactory.fromStrictToModel(CustomTournamentsInvitationForm, viewModel);

        this.tournaments.setTournamentInvitation(form)
            .pipe(
                filter(res => !this.messaging.tryShowError(res)),
                take(1)
            ).subscribe(_ => {
            this.messaging.showMessage('Параметры приглашений обновлены.');
            this.invitationViewModel = new CustomTournamentsInvitationFormViewModel();
            this.pageFiltered();
        });
    }

    private mapToTable(result: CustomTournamentInvitationModel[]): void {
        // as API model and form has same signature - we can map models to strict models
        this.tableData = new GaTableData<CustomTournamentsInvitationFormViewModel>()
            .addSimpleColumn((elem) => elem.tournamentId.strictValue, {title: 'Id турнира', widthSize: 90})
            .addSimpleColumn((elem) => elem.userId.strictValue, {title: 'Id юзера', widthSize: 300})
            .addTemplateColumn(new GaTableCellTemplate(this.templateActions, (elem) => {
                return {
                    viewModel: elem
                };
            }), {title: 'Действия', widthSize: 800});

        const viewModels = result.map(u => {
            const viewModel = new CustomTournamentsInvitationFormViewModel();
            GaStrictModelFactory.fromModelToStrict(viewModel, u, null, null);

            return viewModel;
        });

        this.tableData.setData(viewModels);
    }

    get loading(): boolean {
        return this.searchService.loadingStatus;
    }
}
