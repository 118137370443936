import { Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { WlErpDtTowersIssuedAttemptsApiModel } from '../../../../../../services/autogen/DailyTasks';

@Component({
    selector: 'app-towers-erp-attempts[attempts]',
    templateUrl: './towers-erp-attempts.component.html',
    styleUrls: ['./towers-erp-attempts.component.css']
})
export class TowersErpAttemptsComponent implements OnInit {
    @Input()
    attempts: WlErpDtTowersIssuedAttemptsApiModel[];

    public attemptsText = new Map<number, AttemptInfo>();

    constructor() {
    }

    public ngOnInit(): void {
        this.init();
    }

    public clickText(elem: KeyValue<number, AttemptInfo>): string {
        return elem.value.show ? 'Скрыть' : 'Показать';
    }

    public onClick(elem: KeyValue<number, AttemptInfo>): void {
        elem.value.show = !elem.value.show;
    }

    private init(): void {
        this.attempts.forEach(u => {
            const text = JSON.stringify(u, null, 2);
            this.attemptsText.set(u.id, {id: u.id, text: text, show: false});
        });
    }
}

class AttemptInfo {
    id: number;
    text: string;
    show: boolean;
}
