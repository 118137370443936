import {Component, OnInit} from '@angular/core';
import {AddUserDataViewModel} from '../../../../models/users/add-user-data-view-model';
import {AddUserDataValidator} from '../../validators/add-user-data-validator';
import { AddUserDataForm } from 'src/app/services/autogen/Users';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import {filter} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserContactInformationTypes } from 'src/app/services/autogen/Users';
import {UserContactInformationTypesStrategy} from '../../../shared/common/enum-dropdown/strategies/user-contact-information-types-strategy';
import { UserDataService } from '../../../../services/autogen/Users';

@UntilDestroy()
@Component({
  selector: 'app-users-add-data',
  templateUrl: './users-add-data.component.html',
  styleUrls: ['./users-add-data.component.css']
})
export class UsersAddDataComponent implements OnInit {

  private model: AddUserDataViewModel = new AddUserDataViewModel();

  constructor(private readonly service: UserDataService,
              private readonly validator: AddUserDataValidator,
              private readonly messaging: GaMessagingService,
              public readonly _userContactInformationTypesStrategy: UserContactInformationTypesStrategy) {
  }

  ngOnInit(): void {
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddUserDataForm();
    form.userId = this.userId.strictValue;
    form.contactInfo = this.contactInfo.strictValue;
    form.userContactInformationType = this.userContactInformationType.strictValue;

    this.service.addUserData(form)
      .pipe(
        filter(value => !this.messaging.tryShowError(value)),
        untilDestroyed(this))
      .subscribe(() => this.messaging.showMessage('Данные пользователя успешно добавлены'));
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get userContactInformationType(): StrictFormControl<UserContactInformationTypes> {
    return this.model.userContactInformationType;
  }

  get contactInfo(): StrictFormControl<string> {
    return this.model.contactInfo;
  }
}
