import { AbstractModelValidator, StrictError } from '@koddington/ga-common';
import { UserSegmentationDataViewModel } from '../../../models/segments/user-segmentation-data-view-model';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable()
export class UserSegmentationDataValidator extends AbstractModelValidator<UserSegmentationDataViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId)
            .required()
            .min(1);

        this.ruleForControl(m => m.operationDate)
            .required();

        this.ruleForControl(m => m.registrationDate)
            .required();

        this.ruleForControl(m => m.securityGroup)
            .required();

        this.ruleForControl(m => m.clientName)
            .notEmptyString();

        this.ruleForControl(m => m.phoneNumber)
            .customRule(fieldValue => {
                if (!isNullOrUndefined(fieldValue) && isNaN(Number(fieldValue))) {
                    return new StrictError('phoneNumberProhibitedChar', 'Номер телефона может содержать только цифры');
                }
                return null;
            });

        this.ruleForControl(m => m.email)
            .notEmptyString();

        this.ruleForControl(m => m.registrationSource)
            .min(0);

        this.ruleForControl(u => u.emailRestricted)
            .required();

        this.ruleForControl(u => u.smsRestricted)
            .required();

        this.ruleForControlIf(u => u.emailRestrictionDate, u => u.emailRestricted.strictValue === true)
            .required();

        this.ruleForControlIf(u => u.smsRestrictionDate, u => u.smsRestricted.strictValue === true)
            .required();

        this.ruleForControl(u => u.videoIdentState)
            .required();
    }
}
