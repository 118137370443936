<div>
  <label class="mt-2">Добавление события "Регистрация пользователя" (clients_reg)</label>
  <div>
    <div class="row">
      <app-strict-input-number class="col-2"
                               placeholder="Id пользователя"
                               [control]="model.userId">
      </app-strict-input-number>
      <strict-date-time-picker placeholder="Укажите дату регистрации"
                            class="col-3 timeline-marker"
                            [control]="model.registrationDate">
      </strict-date-time-picker>
      <app-strict-input-text class="col-3"
                             placeholder="Имя пользователя"
                             [control]="model.name">
      </app-strict-input-text>
      <strict-date-time-picker placeholder="Дата рождения"
                            class="col-3"
                            [control]="model.birthday">
      </strict-date-time-picker>
    </div>
    <div class="row">
      <app-strict-autocomplete class="col-2"
                               placeholder="Группы безопасности"
                               [strategy]="securityGroupStrategy"
                               [control]="model.groupId">
      </app-strict-autocomplete>
      <app-strict-input-text class="col-3"
                             placeholder="Номер телефона"
                             [control]="model.phoneNumber">
      </app-strict-input-text>
      <app-strict-input-text class="col-3"
                             placeholder="Почта"
                             [control]="model.email">
      </app-strict-input-text>
      <app-strict-input-number class="col-3"
                               placeholder="Id промо"
                               [control]="model.promoId">
      </app-strict-input-number>
    </div>
    <div class="row mt-2">
      <app-strict-dropdown class="col-2"
                           [control]="model.registrationSource"
                           [strategy]="strategy"
                           [options]="{placeholder: 'Источник регистрации'}">
      </app-strict-dropdown>
      <app-strict-input-text class="col-3"
                             placeholder="Ссылка регистрации"
                             [control]="model.registrationUrl">
      </app-strict-input-text>
      <app-strict-input-number class="col-2"
                               placeholder="Статус регистрации"
                               [control]="model.registrationState">
      </app-strict-input-number>
    </div>
    <div class="row mt-2">
      <app-strict-input-boolean class="col-3"
                                label="Ограничение на рассылку почты"
                                [control]="model.emailRejecting">
      </app-strict-input-boolean>
      <app-strict-input-boolean class="col-3"
                                label="Ограничение на рассылку СМС"
                                [control]="model.smsRejecting">
      </app-strict-input-boolean>
    </div>
    <div class="row">
      <strict-date-time-picker class="col-3"
                            placeholder="Дата запрета почтовых рассылок"
                            [control]="model.emailRejectingDate">
      </strict-date-time-picker>
      <strict-date-time-picker class="col-3"
                            placeholder="Дата запрета СМС рассылок"
                            [control]="model.smsRejectingDate">
      </strict-date-time-picker>
    </div>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
