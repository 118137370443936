import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {StrictFormControl} from '@koddington/ga-common';

/**
 * @deprecated Use `app-strict-input-boolean` instead
 */
@Component({
  selector: 'app-strict-input-boolean-checkbox',
  templateUrl: './strict-input-boolean-checkbox.component.html',
  styleUrls: ['./strict-input-boolean-checkbox.component.css']
})
export class StrictInputBooleanCheckboxComponent implements OnInit {

  @Input()
  public control: StrictFormControl<boolean>;
  @Input()
  public label = '';
  @Input()
  public title = '';
  @Input()
  public className = '';

  @Output()
  public userSelect = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public onSelect(event: MatCheckboxChange): void {
    this.control.strictValue = event.checked;
    this.userSelect.emit(event.checked);
  }
}
