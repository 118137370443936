import {Component, OnInit} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import { GaMessagingService, StrictFormControlBuilder } from '@koddington/ga-common';
import {ActivatedRoute} from '@angular/router';
import {WlRateRaceExchangeRequestEditableViewModel} from '../../../../models/rate-race/wl-rate-race-exchange-request-view-model';
import { WlRateRaceExchangeRequestStatus } from 'src/app/services/autogen/BattlePass';
import { RateRaceMoneyService } from 'src/app/services/autogen/BattlePass';
import {
  WlRateRaceExchangeRequestStatusDropdownStrategy
} from '../../../shared/common/enum-dropdown/strategies/wl-rate-race-exchange-request-status-dropdown-strategy';

@Component({
  selector: 'app-rate-race-exchange-requests',
  templateUrl: './rate-race-exchange-requests.component.html'
})

export class RateRaceExchangeRequestsComponent implements OnInit {

  public results: WlRateRaceExchangeRequestEditableViewModel[];
  public strategy = new WlRateRaceExchangeRequestStatusDropdownStrategy();
  public allStatesControl = StrictFormControlBuilder.buildNullNumber<WlRateRaceExchangeRequestStatus>();

  public sequenceId: number;

  constructor(private readonly service: RateRaceMoneyService,
              private readonly route: ActivatedRoute,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.init();
  }

  public saveNewStatus(result: WlRateRaceExchangeRequestEditableViewModel): void {
    this.service.setStatusForRequest(result.request.id, result.newStatus.strictValue).pipe(
      take(1),
      filter(u => !this.messaging.tryShowError(u))
    ).subscribe(_ => {
      this.messaging.showMessage('Статус изменён');
      this.load();
    });
  }

  public setStatusForAll(): void {
    this.service.setStatusForAllRequests(this.sequenceId, this.allStatesControl.strictValue).pipe(
      take(1),
      filter(u => !this.messaging.tryShowError(u))
    ).subscribe(_ => {
      this.messaging.showMessage('Статус изменён');
      this.load();
    });
  }

  private init(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.sequenceId = Number(routeParams.get('id'));
    this.load();
  }

  private load() {
    this.service.listRequests(this.sequenceId).pipe(
      take(1)
    ).subscribe(u => this.results = u.map(v => {
      const model = new WlRateRaceExchangeRequestEditableViewModel();
      model.request = v;
      model.newStatus.strictValue = v.processingStatus;

      return model;
    }));
  }
}
