<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>
<mat-card *ngIf="!loading">
  <div class="col-4">
    <p class="mat-headline">Тестовый пользователь №{{viewModel.userId.strictValue}}</p>
    <div class="row">
      <div class="col-3">
        <p>Имя:</p>
      </div>
      <div class="col-4">
        <p>{{viewModel.name.strictValue}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p>Номер телефона:</p>
      </div>
      <div class="col-4">
          <app-strict-input-text [control]="phone" [placeholder]="'Номер телефона'">
          </app-strict-input-text>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p>Стадия сегмента:</p>
      </div>
      <div class="col-4">
        <app-strict-dropdown
                [control]="stageType"
                [strategy]="_stageStrategy"
                [options]="{placeholder: 'Не указан'}">
        </app-strict-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p>Монетарный класс:</p>
      </div>
      <div class="col-4">
        <app-strict-dropdown
                [control]="monetaryType"
                [strategy]="_typeStrategy"
                [options]="{placeholder: 'Не указан'}">
        </app-strict-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
      </div>
      <button class="btn wl-btn-active mt-2" (click)="save()">Сохранить</button>
      <button class="btn btn-primary mt-2 ml-2" (click)="backClicked()">Назад</button>
    </div>
  </div>
</mat-card>
