<div class="row">
    <label class="col-1 bt-search-token">Search by token:</label>
    <app-strict-input-text class="col-5" [control]="model.token" [placeholder]="'...Укажите токен устройства'"></app-strict-input-text>
    <div class="col-1 bt-search-token">
      <button class="btn btn-primary search-registrationId-btn" (click)="search()">Поиск</button>
    </div>
</div>
<div class="container-fluid android-div-container-table" *ngIf="!loading">
  <ga-table *ngIf="tableData" [data]="tableData"></ga-table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

