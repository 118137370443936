import { Injectable } from '@angular/core';
import { BlockUIService } from 'ng-block-ui';

@Injectable({
    providedIn: 'root',
})
export class WlBlockUiService {
    constructor(private _blockUI: BlockUIService) {}

    start(elemId?: string, message?: string) {
        return this._blockUI.start(elemId || 'block-ui', message || 'Пожалуйста, подождите');
    }

    stop(elemId?: string) {
        return this._blockUI.stop(elemId || 'block-ui');
    }
}
