import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaMessagingService, GaPanelGridConfig, GaTableCellTemplate, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import {
    DailyTasksErpService, WlErpDtTowersNextDropAttemptApiModel, WlErpDtTowersPromoApiModel,
} from '../../../../services/autogen/DailyTasks';
import { DailyTaskPromoErpViewModel } from '../../view-models/daily-task-promo-erp-view.model';
import { DailyTaskPromoErpValidator } from '../../validators/daily-task-promo-erp-validator.service';
import { NgStyleConfig } from '@koddington/ga-common/lib/types/ng-style-config';

@Component({
    selector: 'app-towers-erp',
    templateUrl: './towers-erp.component.html',
    styleUrls: ['./towers-erp.component.scss']
})
export class TowersErpComponent implements OnInit {
    @ViewChild('attemptsShow') attemptsShow: TemplateRef<any>;
    @ViewChild('daysShow') daysShow: TemplateRef<any>;

    protected filterViewModel = new DailyTaskPromoErpViewModel();
    protected filterInputGrid = new GaPanelGridConfig();

    public tableData: GaTableData<WlErpDtTowersPromoApiModel>;

    private readonly _validator = new DailyTaskPromoErpValidator(false);

    constructor(private readonly _service: DailyTasksErpService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.filterInputGrid
            .addNumbers(this.filterViewModel.userId, 'ID юзера')
            .addNumbers(this.filterViewModel.promoId, 'ID акции');
    }

    public load(): void {
        if (this._validator.validate(this.filterViewModel).length > 0) {
            return;
        }

        this._service.getTowersProfile(this.filterViewModel.userId.strictValue, this.filterViewModel.promoId.strictValue).pipe(
            take(1),
            filter(res => !this._messaging.tryShowError(res))
        ).subscribe(res => {
            this.mapToTable(res.result);
        });
    }

    private mapToTable(result: WlErpDtTowersPromoApiModel): void {
        this.tableData = new GaTableData<WlErpDtTowersPromoApiModel>()
            .setData([result])
            .addSimpleColumn(elem => elem.id, {title: 'Id', widthSize: 60})
            .addSimpleColumn(elem => elem.name, {title: 'Название', widthSize: 160})
            .addSimpleColumn(elem => formatDateTime(elem.startDate), {title: 'Дата начала', widthSize: 150})
            .addSimpleColumn(elem => formatDateTime(elem.technicalEndDate), {title: 'Дата тех.завершения', widthSize: 150})
            .addSimpleColumn(elem => formatDateTime(elem.endDate), {title: 'Дата завершения', widthSize: 150})
            .addSimpleColumn(elem => elem.currentLevel, {title: 'Уровень', widthSize: 150})
            .addSimpleColumn(elem => this.getBonusAttemptProgressText(elem.bonusAttemptProgress), {title: 'Прогресс получения новой попытки', widthSize: 150})
            .addTemplateColumn(new GaTableCellTemplate(this.attemptsShow, elem => {
                return {
                    attempts: elem.issuedAttempts
                };
            }), {title: 'Попытки', widthSize: 420, columnHtmlStyle: this.anchorTopStyleForColumn})
            .addTemplateColumn(new GaTableCellTemplate(this.daysShow, elem => {
                return {
                    days: elem.days
                };
            }), {title: 'Дни', widthSize: 420, columnHtmlStyle: this.anchorTopStyleForColumn });
    }

    private getBonusAttemptProgressText(model: WlErpDtTowersNextDropAttemptApiModel): string {
        if (isNullOrUndefined(model)) {
            return '-';
        }

        if (isNullOrUndefined(model.nextAttemptAmountIn)) {
            return `${model.currentAmount} (получены все попытки)`;
        }

        return `${model.currentAmount}/${model.nextAttemptAmountIn}`;
    }

    private anchorTopStyleForColumn(): NgStyleConfig {
        return ({ 'align-items': 'baseline'});
    }
}
