import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WlResult} from '../../../../models/common/results/wl-result';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, map, take} from 'rxjs/operators';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {
  BattlePassUserProfileBindingViewModel
} from '../../../../models/events/components-models/battle-pass-user-profile-binding-view-model';
import {UserProfileBindingValidator} from '../../../events/validators/user-profile-binding-validator';
import {ActivatedRoute, Params} from '@angular/router';
import { WlGamePlatforms } from 'src/app/services/autogen/BattlePass';
import { AddPlatformBindForm } from 'src/app/services/autogen/BattlePass';
import {GamePlatformsDropdownStrategy} from '../../../shared/common/enum-dropdown/strategies/game-platforms.dropdown-strategy';
import {GaMessagingService, isNullOrUndefined, StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-battle-pass-user-profile-binding',
  templateUrl: './battle-pass-user-profile-binding.component.html'
})
export class BattlePassUserProfileBindingComponent implements OnInit {

  public readonly model: BattlePassUserProfileBindingViewModel = new BattlePassUserProfileBindingViewModel();
  private result: WlResult<boolean>;
  @Output()
  public eventAdded = new EventEmitter();
  isAccessGranted: StrictFormControl<boolean> = new StrictFormControl<boolean>(true);

  constructor(private readonly service: BattlePassService,
              private readonly validator: UserProfileBindingValidator,
              private readonly messaging: GaMessagingService,
              private route: ActivatedRoute,
              readonly strategy: GamePlatformsDropdownStrategy) {
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      map(value =>  this.initModel(value)),
      untilDestroyed(this)
    ).subscribe();
  }

  get isRedirectedSteam(): boolean {
    return this.model.platform.strictValue === WlGamePlatforms.Steam;
  }

  get isRedirectedTwitch(): boolean {
    return this.model.platform.strictValue === WlGamePlatforms.Twitch;
  }

  get isRedirected(): boolean {
    return this.model.secret.hasStrictValue;
  }

  get AccessGranted(): StrictFormControl<boolean> {
    return this.isAccessGranted;
  }

  public add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddPlatformBindForm();
    form.secret = this.model.secret.strictValue;
    form.accountId = this.model.accountId.strictValue ? this.model.accountId.strictValue : null;
    form.userId = this.model.userId.strictValue ? this.model.userId.strictValue : null;
    form.avatarLink = this.model.avatarLink.strictValue;
    form.nickname = this.model.nickname.strictValue;
    form.platform = this.model.platform.strictValue;
    form.isAccessGranted = this.isAccessGranted.strictValue;

    this.service
      .bindingProfile(form)
      .pipe(
        take(1),
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Профиль успешно привязан');
        this.eventAdded.emit();
      });
  }

  private initModel(params: Params): void {
    this.model.secret.strictValue = !isNullOrUndefined(params['userId']) ?
      (params['userId']) : null;
    this.model.platform.strictValue = !isNullOrUndefined(params['platform']) ?
      Number(WlGamePlatforms[params['platform']]) : null;
  }
}
