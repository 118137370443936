<h3>Пользователи попавшие в Rate Race</h3>
<div *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading">
  <div class="row">
    <div class="col-1">
      <app-strict-input-number [title]="'id пользователя'" [control]="userId"></app-strict-input-number>
    </div>
    <div class="col-1">
      <app-strict-input-number [title]="'id сезона'" [control]="seasonId"></app-strict-input-number>
    </div>
    <div class="col-1">
      <button class="btn btn-primary ga-strict-input-label-margin-top" (click)="search()">Найти</button>
    </div>
  </div>
  <table class="table table-hover mt-3">
    <thead>
    <tr class="row table-info">
      <td class="col-1">
        Id пользователя
      </td>
      <td class="col-2">
        Ник пользователя
      </td>
      <td class="col-1">
        Id аватарки
      </td>
      <td class="col-1">
        Id сезона
      </td>
      <td class="col-1">
        Дата регистрации
      </td>
      <td class="col-1">
        Дата добавления
      </td>
      <td class="col-1">
        Id запроса
      </td>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let result of pagedResult.results" class="row" style="cursor: pointer;">
      <td class="col-1">
        {{result.userId}}
      </td>
      <td class="col-2">
        {{result.nickname}}
      </td>
      <td class="col-1">
        {{result.avatarId}}
      </td>
      <td class="col-1">
        {{result.seasonId}}
      </td>
      <td class="col-1">
        {{result.registrationDate | gaDateTime}}
      </td>
      <td class="col-1">
        {{result.createAt | gaDateTime}}
      </td>
      <td class="col-1">
        {{result.sequenceId}}
      </td>
    </tr>
    </tbody>
  </table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
