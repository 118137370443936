import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import {UserViewModel} from '../../../../models/users/user-view-model';
import {UserValidator} from '../../validators/user-validator';
import { AddOrUpdateUserForm } from 'src/app/services/autogen/Users';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UsersService } from 'src/app/services/autogen/Users';
import {WlResult} from '../../../../models/common/results/wl-result';
import { StageTypesDropdownStrategy } from '../../../shared/common/enum-dropdown/strategies/stage-types.dropdown-strategy';
import { WlStageTypes } from 'src/app/services/autogen/Users';
import { MonetaryClassTypesDropdownStrategy } from '../../../shared/common/enum-dropdown/strategies/monetary-class-types.dropdown-strategy';
import { WlMonetaryClassTypes } from 'src/app/services/autogen/Users';

@UntilDestroy()
@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html'
})
export class UsersAddComponent implements OnInit {
  private result: WlResult<boolean>;
  private readonly model: UserViewModel = new UserViewModel();

  @Output()
  private userAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly service: UsersService,
              private readonly validator: UserValidator,
              private readonly messaging: GaMessagingService,
              public readonly _stageStrategy: StageTypesDropdownStrategy,
              public readonly _typeStrategy: MonetaryClassTypesDropdownStrategy) {
  }

  ngOnInit(): void {
  }

  save(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddOrUpdateUserForm();
    form.name = this.model.name.strictValue;
    form.phone = this.model.phone.strictValue;
    form.userId = this.model.userId.strictValue;
    form.stageType = this.model.stage.strictValue;
    form.monetaryClassType = this.model.monetaryClass.strictValue;
    this.service.addOrUpdate(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Пользователь успешно добавлен');
        this.userAdded.emit(value.result);
      });
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get name(): StrictFormControl<string> {
    return this.model.name;
  }

  get phone(): StrictFormControl<string> {
    return this.model.phone;
  }

  get stageType(): StrictFormControl<WlStageTypes> {
    return this.model.stage;
  }

  get monetaryType(): StrictFormControl<WlMonetaryClassTypes> {
    return this.model.monetaryClass;
  }
}
