<h3>Профиль пользователя "Зимний Кубок РПЛ"</h3>

<div class="row">
    <ga-panel-grid [config]="filterInputGrid"/>
    <span>
        <button class="btn btn-primary mt-2" (click)="load()">Загрузить</button>
    </span>
</div>
<div *ngIf="tableData" class="row">
    <ga-table [data]="tableData"></ga-table>
</div>

<ng-template #daysShow let-days="days">
    <app-winter-league-erp-days [days]="days"></app-winter-league-erp-days>
</ng-template>
