<app-loading *ngIf="loading"></app-loading>

<div class="row border mt-2">
  <div class="col-2">
    <label><b>Телефон:</b></label>
    <label>{{phone.phoneNumber}}</label>
    <br/>
    <label><b>Id телефона:</b></label>
    <label>{{phone.id}}</label>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultSmsTrafficSendingCode"
              [strategy]="smsGateErrorStrategy"
              [options]="{title: 'Код отправки через смс траффик'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultMtsSendingCode"
              [strategy]="smsGateErrorStrategy"
              [options]="{title: 'Код отправки через мтс'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultMtsExoSendingCode"
              [strategy]="smsGateErrorStrategy"
              [options]="{title: 'Код отправки через мтс exo'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultSmsTrafficConversionCode"
              [strategy]="smsTrafficConversionCodeStrategy"
              [options]="{title: 'Код конверсии через смс траффик'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultMtsCommunicatorConversionCode"
              [strategy]="mtsCommunicatorConversionCodeStrategy"
              [options]="{title: 'Код конверсии через мтс'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <app-strict-dropdown
              [control]="viewModel.defaultMtsExoConversionCodes"
              [strategy]="mtsExoConversionCodeStrategy"
              [options]="{title: 'Код конверсии через мтс exo'}">
      </app-strict-dropdown>
    </div>
  </div>
  <div class="col-1">
    <button type="submit" class="btn btn-primary mt-4" (click)="saveChanges()">Сохранить</button>
  </div>
</div>
