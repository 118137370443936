import { Routes } from '@angular/router';
import { DailyTasksLayoutComponent } from './components/daily-tasks-layout/daily-tasks-layout.component';
import { DailyTasksErpComponent } from './components/daily-tasks-erp/daily-tasks-erp.component';
import { PickemErpComponent } from './components/pickem-erp/pickem-erp.component';
import { TowersErpComponent } from './components/towers-erp/towers-erp.component';
import { WinterLeagueErpComponent } from './components/winter-league-erp/winter-league-erp.component';

export const ChildrenMockRoute: Routes = [
    {
        path: 'erp',
        component: DailyTasksErpComponent
    },
    {
        path: 'pickem-erp',
        component: PickemErpComponent
    },
    {
        path: 'towers-erp',
        component: TowersErpComponent
    },
    {
        path: 'winter-league-erp',
        component: WinterLeagueErpComponent
    },
];

export const DailyTasksRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'main',
                component: DailyTasksLayoutComponent,
                children: ChildrenMockRoute
            }
        ]
    }
];
