import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, map, take } from 'rxjs/operators';
import { WlMonetaryClassTypes } from 'src/app/services/autogen/Users';
import { WlStageTypes } from 'src/app/services/autogen/Users';
import { UserViewModel } from '../../../../models/users/user-view-model';
import { MonetaryClassTypesDropdownStrategy } from '../../../shared/common/enum-dropdown/strategies/monetary-class-types.dropdown-strategy';
import { StageTypesDropdownStrategy } from '../../../shared/common/enum-dropdown/strategies/stage-types.dropdown-strategy';
import { Location } from '@angular/common';
import { UsersService } from 'src/app/services/autogen/Users';
import { AddOrUpdateUserForm } from 'src/app/services/autogen/Users';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateUserDataValidator } from '../../validators/update-user-data-validator';
import { WlUser } from 'src/app/services/autogen/Users';
import {GaMessagingService, isNullOrUndefined, StrictError, StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  public loading = true;
  private viewModel: UserViewModel = new UserViewModel();
  private errors: StrictError[] = [];

  constructor(private readonly _route: ActivatedRoute,
    private readonly _userService: UsersService,
    private readonly _messaging: GaMessagingService,
    private _location: Location,
    private readonly validator: UpdateUserDataValidator,
    public readonly _typeStrategy: MonetaryClassTypesDropdownStrategy,
    public readonly _stageStrategy: StageTypesDropdownStrategy) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.loading = true;
    this._userService.byUserId(this.id)
      .pipe(take(1),
            map(result => this.initModel(result.result)),
            finalize(() => this.loading = false)
      ).subscribe();
  }

  backClicked() {
    this._location.back();
  }

  save(): void {
    this.errors = this.validator.validate(this.viewModel);
    if (this.errors.length > 0) {
      return;
    }
    const form = new AddOrUpdateUserForm();
    form.userId = this.viewModel.userId.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.phone = this.viewModel.phone.strictValue;
    form.stageType = this.viewModel.stage.strictValue;
    form.monetaryClassType = this.viewModel.monetaryClass.strictValue;

    this._userService.addOrUpdate(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => this._messaging.showMessage('Пользователь успешно добавлен'));
  }

  get id(): number {
    return this._route.snapshot.params['id'];
  }

  get phone(): StrictFormControl<string> {
    return this.viewModel.phone;
  }

  get monetaryType(): StrictFormControl<WlMonetaryClassTypes> {
    return this.viewModel.monetaryClass;
  }

  get stageType(): StrictFormControl<WlStageTypes> {
    return this.viewModel.stage;
  }

  private initModel(user: WlUser): void {
    this.viewModel.userId.strictValue = !isNullOrUndefined(user.userId) ?
      user.userId : null;
    this.viewModel.name.strictValue = !isNullOrUndefined(user.name) ?
      user.name : null;
    this.viewModel.phone.strictValue = !isNullOrUndefined(user.phone) ?
      user.phone : null;
    this.viewModel.stage.strictValue = !isNullOrUndefined(user.stageType) ?
      user.stageType : null;
    this.viewModel.monetaryClass.strictValue = !isNullOrUndefined(user.monetaryClassType) ?
      user.monetaryClassType : null;
  }
}
