import { Component, OnInit } from '@angular/core';
import { UserSegmentationDataViewModel } from '../../../../models/segments/user-segmentation-data-view-model';
import { ClientInformationChangeForm } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { UserSegmentationDataValidator } from '../../../segments/validators/user-segmentation-data-validator';
import { EventsService } from 'src/app/services/autogen/Events';
import { SecurityGroupsStrategy } from '../../../shared/common/strict-autocomplete/strategies/security-groups-strategy';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { RegistrationPlatformStrategy } from '../../../shared/common/enum-dropdown/strategies/registration-platfroms-strategy';

@Component({
    selector: 'app-client-information',
    templateUrl: './client-information.component.html'
})
export class ClientInformationComponent implements OnInit {

    public readonly emitGroupEventModel = new UserSegmentationDataViewModel();
    public readonly securityGroupStrategy: SecurityGroupsStrategy;

    constructor(private readonly _validator: UserSegmentationDataValidator,
                private readonly _messaging: GaMessagingService,
                private readonly _eventsService: EventsService,
                readonly strategy: RegistrationPlatformStrategy,
                _securityGroups: SecurityGroupsService) {
        this.securityGroupStrategy = new SecurityGroupsStrategy(_securityGroups);
    }

    ngOnInit(): void {
    }

    public emitChangeInformationEvent(): void {
        const errors = this._validator.validate(this.emitGroupEventModel);
        if (errors.length > 0) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(ClientInformationChangeForm, this.emitGroupEventModel, null,
            [
                {
                    sourceKey: 'securityGroup',
                    targetKey: 'securityGroupId',
                    newValue: this.emitGroupEventModel.securityGroup.strictValue?.id
                }
            ]);

        this._eventsService.clientInformationChange(form)
            .pipe(
                take(1),
                filter(value => !this._messaging.tryShowError(value))
            )
            .subscribe(() => this._messaging.showMessage('Событие добавлено'));
    }
}
