<h1>Запросы на срабатывание механик</h1>
<table class="table mt-3">
  <tr class="d-flex">
    <td class="col-1">
      Id
    </td>
    <td class="col-1">
      Id пользователя
    </td>
    <td class="col-1">
      ID начисленной механики
    </td>
    <td class="col-1">
      Размер бонуса
    </td>
    <td class="col-2">
      Имя механики
    </td>
    <td class="col-1">
      Дата
    </td>
    <td class="col-2">
      <app-strict-dropdown
              [control]="allStatesControl"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="setStatusForAll()">Сохранить для всех</button>
    </td>
  </tr>
  <tr class="d-flex" *ngFor="let model of models">
    <td class="col-1">
      {{model.request.id}}
    </td>
    <td class="col-1">
      {{model.request.userId}}
    </td>
    <td class="col-1">
      {{model.request.userMechanicId}}
    </td>
    <td class="col-1" style="word-break: break-all;">
      {{model.request.bonusValue}}
    </td>
    <td class="col-2">
      {{model.request.mechanic?.name}}
    </td>
    <td class="col-1">
      {{model.request.creationDate | moscowDateTime}}
    </td>
    <td class="col-2">
      <app-strict-dropdown
              [control]="model.newStatus"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="saveNewStatus(model)">Сохранить</button>
    </td>
  </tr>
</table>
