<h2>Панель управления состоянием методов апи винлайна</h2>
<app-loading *ngIf="loading"></app-loading>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.clientsRegistrationsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.clientsRegistrationsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onClientsRegistrationsMethodChange()"
            [options]="{title: 'Регистрации клиентов'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.balanceByLoginsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.balanceByLoginsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onBalanceByLoginsMethodChange()"
            [options]="{title: 'Балансы по id пользователей'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.docsAndSelfiesMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.docsAndSelfiesMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onDocsAndSelfiesMethodChange()"
            [options]="{title: 'События загрузки селфи и документов'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsEnablingQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mechanicsEnablingQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMechanicEnablingChange()"
            [options]="{title: 'Очередь подключения механик'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsTriggeringQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mechanicsTriggeringQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMechanicTriggeringChange()"
            [options]="{title: 'Очередь срабатывания механик'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsBonusUsingQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mechanicsBonusUsingQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMechanicBonusUsingChange()"
            [options]="{title: 'Очередь использования бонусов механик'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.betsState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.betsState"
            [strategy]="dropdownStrategy"
            (userSelect)="onBetsChange()"
            [options]="{title: 'Ставки(stavki_date_st)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaBtagsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mediaBtagsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMediaBtagsMethodChange()"
            [options]="{title: 'Получение связей по медиа(media_btags_dt)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaPromoIdsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mediaPromoIdsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMediaPromoIdsMethodChange()"
            [options]="{title: 'Получение связей по медиа(media_promo_dt)'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaSourcesMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mediaSourcesMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMediaSourcesMethodChange()"
            [options]="{title: 'Медиа справочники источников(media_source_dt)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaChannelsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mediaChannelsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMediaChannelsMethodChange()"
            [options]="{title: 'Медиа справочники каналов(media_channels_dt)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaUserBtagsMethodState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.mediaUserBtagsMethodState"
            [strategy]="dropdownStrategy"
            (userSelect)="onMediaUserBtagsMethodChange()"
            [options]="{title: 'Медиа получение ID btag(media_btag_cl_dt)'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.uniQueueForBroadcastState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.uniQueueForBroadcastState"
            [strategy]="dropdownStrategy"
            (userSelect)="onUniQueueChange()"
            [options]="{title: 'Получение рассылок от бэка по исключающему списку(uni_queue)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.uniQueueBroadcastCsvDownloadState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.uniQueueBroadcastCsvDownloadState"
            [strategy]="dropdownStrategy"
            (userSelect)="onUniQueueBroadcastCsvDownloadState()"
            [options]="{title: 'Скачивание csv файла рассылки от бэка(uni)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.pushInfoState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.pushInfoState"
            [strategy]="dropdownStrategy"
            (userSelect)="onPushInfoChange()"
            [options]="{title: 'Получение рассылок от бэка по списку(push_info)'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.battlePassFreeBetQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.battlePassFreeBetQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onBattlePassFreeBetQueueChange()"
            [options]="{title: 'Обработка фрибетов по БП (uni_queue, очередь 105)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.logoServiceState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.logoServiceState"
            [strategy]="dropdownStrategy"
            (userSelect)="onLogoServiceChange()"
            [options]="{title: 'Внешний доступ к лого-сервису'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.customTournamentState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.customTournamentState"
            [strategy]="dropdownStrategy"
            (userSelect)="onCustomTournamentChange()"
            [options]="{title: 'Кастомные турниры (300 очередь)'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.rateRaceExchangeQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.rateRaceExchangeQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onRateRaceExchangeQueueChange()"
            [options]="{title: 'Обработка обмена по Rate Race (uni_queue 106)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.userRateRaceQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.userRateRaceQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onUserRateRaceQueueChange()"
            [options]="{title: 'Обработка пользователей в Rate Race (uni_queue 400)'}">
    </app-strict-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.rateRaceResultsQueueState.strictValue !== apiStates.Ok">
    <app-strict-dropdown
            [control]="viewModel.rateRaceResultsQueueState"
            [strategy]="dropdownStrategy"
            (userSelect)="onRateRaceResultsQueueChange()"
            [options]="{title: 'Обработка результатов Rate Race (uni_queue 401)'}">
    </app-strict-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
    <div class="col-3 current-state"
         [class.not-ok]="viewModel.cashbackBonusState.strictValue !== apiStates.Ok">
      <app-strict-dropdown
              [control]="viewModel.cashbackBonusState"
              [strategy]="dropdownStrategy"
              (userSelect)="onCashbackBonusQueueChange()"
              [options]="{title: 'Получение игроков, не забравших бонусы Cashback (clients_cashbackBonus)'}">
      </app-strict-dropdown>
    </div>
</div>
