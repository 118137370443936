<form (submit)="save()" style="width: 500px">
  <h1 class="mt-2">Создать смс аккаунт</h1>
  <div class="form-group">
    <label>Логин (для мтс коммуникатора логин не используется при авторизации)</label>
    <app-strict-input-text [control]="this.viewModel.login">
    </app-strict-input-text>
  </div>
  <div class="form-group">
    <label>Пароль</label>
    <app-strict-input-text [control]="this.viewModel.password">
    </app-strict-input-text>
  </div>
  <div class="form-group">
    <app-strict-dropdown
            [control]="viewModel.type"
            [strategy]="dropdownStrategy"
            [options]="{title: 'Тип'}">
    </app-strict-dropdown>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
</form>
