<ng-container>
<div class="row mt-3">
  <div class="col-1">
    {{viewModel.id}}
  </div>
  <div class="col-1">
    {{viewModel.requestId}}
  </div>
  <div class="col-2">
    {{viewModel.tradeLinkUrl}}
  </div>
  <div class="col-1">
    {{viewModel.itemId}}
  </div>
  <div class="col-1">
    {{viewModel.hashName}}
  </div>
  <div class="col-1">
    {{viewModel.operation}}
  </div>
  <div class="col-1">
    <app-strict-dropdown
            [control]="viewModel.status"
            [strategy]="responseTypesStrategy"
            [options]="{placeholder: 'Выберите статус'}">
    </app-strict-dropdown>
  </div>
  <div class="col-2">
    <app-strict-dropdown
            [control]="viewModel.errorCode"
            [strategy]="responseCodeStrategy"
            [options]="{placeholder: 'Свой код ответа'}">
    </app-strict-dropdown>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.comment"></app-strict-input-text>
  </div>
  <div class="col-1">
    <button class="btn btn-primary mt-2" (click)="sendResponse()">Отправить</button>
  </div>
</div>
</ng-container>
