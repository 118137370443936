<ng-container>
    <div class="row">
        <div class="col-1">
            {{viewModel.userId}}
        </div>
        <div class="col-1">
            {{viewModel.subscriptionId}}
        </div>
        <div class="col-1">
            {{viewModel.questId}}
        </div>
        <div class="col-1">
            {{viewModel.currentProgress}}
        </div>
        <div class="col-1">
            {{viewModel.subscriptionDateTime | dateTime}}
        </div>
        <div class="col-1">
            {{viewModel.completeDateTime | dateTime}}
        </div>
      <div class="col-1">
            {{viewModel.isManualCompletion}}
        </div>
        <div class="col-2">
            <app-strict-dropdown
                    [control]="viewModel.statusCode"
                    [strategy]="responseCodeStrategy"
                    [options]="{placeholder: 'Свой код ответа'}">
            </app-strict-dropdown>
        </div>
        <div class="col-1">
            <app-strict-input-text [control]="viewModel.status"></app-strict-input-text>
        </div>
        <div class="col-2">
            <app-strict-input-text [control]="viewModel.message"></app-strict-input-text>
        </div>
        <div class="col-1">
            <button class="btn btn-primary mt-2" (click)="sendResponse()">Отправить</button>
        </div>
    </div>
</ng-container>
