import { Component } from '@angular/core';
import { UserTriggersService } from 'src/app/services/autogen/Triggers';
import {filter, finalize, take} from 'rxjs/operators';
import {GaMessagingService} from '@koddington/ga-common';

@Component({
  selector: 'app-trigger-actions',
  templateUrl: './trigger-actions.component.html',
  styleUrls: ['./trigger-actions.component.css']
})
export class TriggerActionsComponent {

  loading = false;

  constructor(private readonly _userTriggers: UserTriggersService
  , private readonly _messaging: GaMessagingService) { }

  deactivateAll() {
    this.loading = true;
    this._userTriggers.deactivateAllUserTriggers().pipe(
      take(1),
      filter(value => !this._messaging.tryShowError(value)),
      finalize(() => this.loading = false)
    ).subscribe(result => {
      result.result ? this._messaging.showMessage('Готово') : this._messaging.showMessage('Незавершено');
    });
  }
}
