<h1>Запросы на обмен награды по Rate Race для последовательности {{sequenceId}}</h1>
<table class="table mt-3">
  <tr class="d-flex">
    <td class="col-1">
      Id
    </td>
    <td class="col-1">
      ID пользователя
    </td>
    <td class="col-1">
      ID операции
    </td>
    <td class="col-1">
      Сумма
    </td>
    <td class="col-2">
      Дата последней обработки
    </td>
    <td class="col-2">
      Статус
    </td>
    <td class="col-2">
      <app-strict-dropdown
              [control]="allStatesControl"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="setStatusForAll()">Сохранить для всех</button>
    </td>
  </tr>

  <tr *ngFor="let result of results" class="d-flex">
    <td class="col-1">
      {{result.request.id}}
    </td>
    <td class="col-1">
      {{result.request.userId}}
    </td>
    <td class="col-1">
      {{result.request.operationId}}
    </td>
    <td class="col-1">
      {{result.request.amount}}
    </td>
    <td class="col-2">
      {{result.request.updatedAt | dateTime}}
    </td>
    <td class="col-2">
      <app-strict-dropdown
              [control]="result.newStatus"
              [strategy]="strategy"
              [options]="{placeholder: 'Выберите тип'}">
      </app-strict-dropdown>
      <button class="btn btn-primary" (click)="saveNewStatus(result)">Сохранить</button>
    </td>
  </tr>
</table>
