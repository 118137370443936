import {Component, OnInit} from '@angular/core';
import { UserMechanicsProcessingService } from 'src/app/services/autogen/UserMechanics';
import {MechanicEnablingRequestStatusDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/mechanic-enabling-request-status.dropdown-strategy';
import {MechanicEnablingRequestStatusValidator} from '../validators/mechanic-enabling-request-status-validator';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute} from '@angular/router';
import {UserMechanicsNavigationService} from '../services/user-mechanics-navigation.service';
import {MechanicEnablingRequestStatusViewModel} from '../../../models/mechanics/mechanic-enabling-request-status-view-model';
import { WinlineMechanicEnablingStatus } from 'src/app/services/autogen/UserMechanics';
import { GaMessagingService, StrictFormControlBuilder } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanics-enabling-request',
  templateUrl: './user-mechanics-enabling-requests.component.html',
  styleUrls: ['./user-mechanics-enabling-requests.component.css']
})
export class UserMechanicsEnablingRequestsComponent implements OnInit {

  public models: MechanicEnablingRequestStatusViewModel[] = [];
  public allStatesControl = StrictFormControlBuilder.buildNullNumber<WinlineMechanicEnablingStatus>();

  private _sequenceId: number;

  constructor(public readonly strategy: MechanicEnablingRequestStatusDropdownStrategy,
              private readonly processingService: UserMechanicsProcessingService,
              private readonly validator: MechanicEnablingRequestStatusValidator,
              private readonly navigation: UserMechanicsNavigationService,
              private readonly route: ActivatedRoute,
              private readonly messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this._sequenceId = Number(routeParams.get('id'));
    this.load();
  }

  public setStatusForAll(): void {
    if (!this.allStatesControl.hasStrictValue) {
      this.messages.showMessage('Выберите статус');
      return;
    }

    this.processingService.rawSetStatusForAllEnablingRequest(this._sequenceId, this.allStatesControl.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  public saveNewStatus(model: MechanicEnablingRequestStatusViewModel): void {
    const errors = this.validator.validate(model);
    if (errors.length > 0) {
      return;
    }

    this.processingService.setStatusForEnablingRequest(model.request.id, model.newStatus.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  private load(): void {
    this.processingService.getEnablingRequests(this._sequenceId)
      .pipe(
        take(1),
        filter((res) => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.models = value.result.map(u => {
          return {
            request: u,
            newStatus: StrictFormControlBuilder.buildNullNumber<WinlineMechanicEnablingStatus>().withValue(u.processingStatus)
          };
        });
      });
  }
}
