import { Component, OnInit } from '@angular/core';
import { AddBattlePassQuestProgressFormViewModelValidator } from 'src/app/services/autogen/BattlePass';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { AddBattlePassQuestProgressFormViewModel } from 'src/app/services/autogen/BattlePass';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import { GaMessagingService } from '@koddington/ga-common';

@UntilDestroy()
@Component({
    selector: 'app-add-battle-pass-quest-progress',
    templateUrl: './add-battle-pass-quest-progress.component.html'
})

export class AddBattlePassQuestProgressComponent implements OnInit {

    public readonly model: AddBattlePassQuestProgressFormViewModel = new AddBattlePassQuestProgressFormViewModel();
    private readonly validator = new AddBattlePassQuestProgressFormViewModelValidator();

    constructor(private readonly service: BattlePassService,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
    }

    public add(): void {
        if (!this.validator.isValid(this.model)) {
            return;
        }
        const form = this.model.toForm();

        this.service
            .addUserQuestProgress(form)
            .pipe(
                take(1),
                filter(u => !this.messaging.tryShowError(u))
            )
            .subscribe(() => this.messaging.showMessage('Прогресс успешно добавлен'));
    }
}
